import Exception from '@/exceptions/Exception'
import { i18n } from '@/plugins/i18n'


export default class BadRequestException extends Exception {
	constructor(message = null) {
		if (message === null || message === '') {
			message = i18n.t('errors.bad_request')
		}
		super(message, 'BadRequestException')
	}
}
